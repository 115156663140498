<template>
    <div class="d-flex flex-wrap">
        <div class="col-12" v-if="pendingPayments && pendingPayments.length > 0">
            <SIAPayments :amount="pendingPayments[0].total_amount"/>
        </div>
        <div class="col-12">
            <DataTable :date="selectedDate" :tbody="payments" @filterByDate="filterByDate" @getDetails="details = $event" v-if="!loader && payments"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <PaymentDetails :details="details" @getReceipt="getReceipt" @close="details = null"/>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="receipt" @close="receipt = null">
            <template v-slot:body>
                <PaymentReceipt :receipt="receipt" @downloadReceipt="downloadReceipt" @close="receipt = null"/>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import html2pdf from "html2pdf.js"
import DataTable from "@/components/DataTables/PaymentsDataTable.vue"
import Modal from '@/components/Modal.vue'
import SIAPayments from '@/components/SIAPayments.vue'
import PaymentDetails from '../../../../components/Dashboard/Travels/Modals/PaymentDetails.vue'
import PaymentReceipt from '../../../../components/Dashboard/Travels/Modals/PaymentReceipt.vue'
export default {
    name: 'PaymentsView',
    components: {
        DataTable,
        Modal,
        SIAPayments,
        PaymentDetails,
        PaymentReceipt,
    },
    computed: {
        ...mapState(['loader', 'account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    data() {
        return {
            payments: null,
            pendingPayments: null,
            selectedDate: null,
            succeeded: true,
            failed: true,
            details: null,
            receipt: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setErrorFromServer']),
        getPayments(startDate, endDate, succeeded, failed) {
            this.setLoader(true)
            if (!startDate && !endDate) {
                startDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
            }
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    var AliasPanPath = ''
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        AliasPanPath += `${bank_card.alias_pan}/`
                    }
                    this.$http
                        .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/0/0/4:desc/${succeeded}/${failed}/${startDate}/${endDate}/${AliasPanPath}`)
                        .then((res) => {
                            var payments = res.data.payload
                            for (var i = 0; i < payments.length; i++) {
                                var payment = payments[i]
                                payment.BankCard.label = this.$formatLabel(payment.BankCard.label)
                                payment.Payments.amount = this.$formatAmount(payment.Payments.amount, payment.Payments.currencies_code)
                                payment.Payments.created = this.$formatDate(payment.Payments.created, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                payment.Payments.updated = this.$formatDate(payment.Payments.updated, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                            }
                            this.getPendingPayments(AliasPanPath)
                            this.payments = payments
                            this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                            this.setLoader(false)
                        })
                        .catch((err) => {
                            this.setErrorFromServer(err.message)
                            this.setLoader(false)
                        })
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        getPendingPayments(AliasPanPath) {
            this.$http
                .get(`${this.omsApiPath}/payments/get_failed_summaries/${this.omsApiKey}/0/0/${AliasPanPath}`)
                .then((res) => {
                    var pendingPayments = res.data.payload
                    this.pendingPayments = pendingPayments
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        filterByDate(when) {
            this.payments = null
            this.getPayments(when.startDate, when.endDate, this.succeeded, this.failed)
        },
        getReceipt(short_id) {
            this.setLoader(true)
            this.$http
                .post(`${this.omsApiPath}/payments/get_info`, {short_id}, {
                    headers: {
                        "api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    var info = res.data
                    info.bank_card.label = this.$formatLabel(info.bank_card.label)
                    info.payment.amount = this.$formatAmount(info.payment.amount, info.payment.currencies_code)
                    for (var i = 0; i < info.tickets.length; i++) {
                        var ticket = info.tickets[i]
                        ticket.valid_from = this.$formatDate(ticket.valid_from, this.account.locales_id, ticket.time_zone)
                        ticket.valid_to = this.$formatDate(ticket.valid_to, this.account.locales_id, ticket.time_zone)
                        ticket.ticket_amount = this.$formatAmount(ticket.ticket_amount, info.payment.currencies_code)
                        
                        if (ticket.first_validation_transit_data) {
                            var first_validation_transit_data = []
                            for (const [key, val] of Object.entries(ticket.first_validation_transit_data)) {
                                first_validation_transit_data.push({key, val})
                            }
                            ticket.first_validation_transit_data = first_validation_transit_data
                        }

                        if (ticket.last_validation_transit_data) {
                            var last_validation_transit_data = []
                            for (const [key, val] of Object.entries(ticket.last_validation_transit_data)) {
                                last_validation_transit_data.push({key, val})
                            }
                            ticket.last_validation_transit_data = last_validation_transit_data
                        }
                    }
                    this.receipt = info
                    this.setLoader(false)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        setupPdf() {
            return new Promise((resolve) => {
                html2pdf(document.getElementById('receipt'), {
                    margin: 1,
                    filename: `${this.$t('Dashboard.Payments.Modals.PaymentReceipt.labels.receipt')}-${this.receipt.payment.short_id}.pdf`
                })
                resolve()
            })
            
        }, 
        downloadReceipt() {
            this.setupPdf()
        }
    },
    created() {
        this.getPayments(null, null, this.succeeded, this.failed)
    }
}
</script>