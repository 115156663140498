import { createStore } from 'vuex'
import { googleLogout } from 'vue3-google-login'
import crypto from 'crypto-js'
import router from '../router'
import config from '../../config.json'
import i18n from '../lang/i18n.js'
import version from '../../version.json'

const store = createStore({
    state () {
      return {
        config,
        version,
        tickets: [],
        payments: [],
        receipt: null,
        pans: [],
        loader: false,
        stream: null,
        identityVerifier: {
            document_primary: null,
            document_secondary: null,
            biometric_photo: null
        },
        errorFromServer: null,
        supportedLanguages: null,
        facebookSdk: null,
        account: null,
        BankCards: []
      }
    },
    mutations: {
        setErrorFromServer(state, error) {
            switch (error) {
                case 'Network Error':
                    state.errorFromServer = i18n.global.t('ErrorFromServer')
                    break;
            
                default:
                    state.errorFromServer = error
                    break;
            }
        },
        setSupportedLanguages(state, languages) {
            state.supportedLanguages = languages
        },
        setFacebookSdk(state, sdk) {
            state.facebookSdk = sdk
        },
        setAccount(state, account) {
            if (account) {
                localStorage.setItem('session_account', account)
                account = crypto.AES.decrypt(account, '147258369').toString(crypto.enc.Utf8)
                state.account = JSON.parse(account)
                localStorage.setItem('session_language', state.account.languages_id)
            }
        },
        setBankCards(state, bank_cards) {
            state.BankCards = bank_cards
        },
        setLoader(state, loader) {
            state.loader = loader
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.account
        },
        apiPath(state) {
            return state.config.api_url
        },
        omsApiPath(state) {
            return state.config.oms_api.url
        },
        omsApiKey(state) {
            return state.config.oms_api.key
        }
    },
    actions: {
        autoLogin (context) {
            let session_account = localStorage.getItem('session_account')
            if (session_account) {
                context.commit('setAccount', session_account)
            }
        },
        logout ({commit, state}) {
            if (state.account.google_id != null) {
                googleLogout()
            }
            if (state.account.facebook_id != null) {
                state.facebookSdk.FB.logout()
            }
            commit('setAccount', null)
            localStorage.removeItem('session_account')
            router.go('/')
        }
    }
})

export default store