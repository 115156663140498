<template>
    <div class="d-flex flex-wrap bg-warning sia_payments" v-if="SIARequest">
        <div class="col-12 mb-2">
            <h3 class="fw-bold">
                {{ $t('Dashboard.Payments.Modals.PendingPayments.pending') }}
            </h3>
        </div>
        <div class="col-12 mb-4">
            <h5>
                {{ $t('Dashboard.Payments.Modals.PendingPayments.online_payments') }}
            </h5>
        </div>
        <div class="col-12 text-end">
            <form name="frm" id="frm" method="post" :action="config.tokenizer.SIA.ENDPOINT">
                <input type="hidden" name="AMOUNT" :value="SIARequest.AMOUNT">
                <input type="hidden" name="EMAIL" :value="SIARequest.EMAIL">
                <input type="hidden" name="SHOPEMAIL" :value="SIARequest.SHOPEMAIL">
                <input type="hidden" name="ORDDESCR" :value="SIARequest.ORDDESCR">
                <input type="hidden" name="CURRENCY" :value="config.tokenizer.SIA.CURRENCY">
                <input type="hidden" name="SHOPID" :value="config.tokenizer.SIA.SHOPID_PAY">
                <input type="hidden" name="ORDERID" :value="SIARequest.ORDERID">
                <input type="hidden" name="OPTIONS" value="V">
                <input type="hidden" name="URLDONE" :value="SIARequest.URLDONE">
                <input type="hidden" name="URLMS" :value="config.tokenizer.SIA.URLMS_PAY">
                <input type="hidden" name="URLBACK" :value="config.tokenizer.SIA.URLBACK_PAY">
                <input type="hidden" name="ACCOUNTINGMODE" :value="config.tokenizer.SIA.ACCOUNTINGMODE_PAY">
                <input type="hidden" name="AUTHORMODE" :value="config.tokenizer.SIA.AUTHORMODE">
                <input type="hidden" name="MAC" :value="SIARequest.MAC">
                <input type="hidden" name="REQ" :value="SIARequest.request">
                <button type="submit" class="btn">
                    {{ $t('Dashboard.Payments.Modals.PendingPayments.pay_now') }}
                </button>
            </form>
        </div>
    </div>

</template>

<script>
const { v4: uuidv4 } = require('uuid')
import crypto from 'crypto-js'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'SIAPayments',
    computed: {
        ...mapState(['config', 'account']),
        ...mapGetters(['apiPath', 'omsApiKey'])
    },
    props: {
        amount: Number
    },
    data() {
        return {
            SIARequest: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        getSIARequest(amount) {
            var siaRequest = {
                URLDONE: this.config.tokenizer.SIA.URLDONE_PAY,
                ORDERID: uuidv4(),
                EMAIL: this.account.email,
                SHOPEMAIL: this.config.tokenizer.SIA.SHOPEMAIL,
                ORDDESCR: 'pippo',
                request : null,
                MAC: null
            }

            var request = `URLMS=${this.config.tokenizer.SIA.URLMS_PAY}&URLDONE=${siaRequest.URLDONE_PAY}&ORDERID=${siaRequest.ORDERID}&SHOPID=${this.config.tokenizer.SIA.SHOPID_PAY}&AMOUNT=${amount}&CURRENCY=${this.config.tokenizer.SIA.CURRENCY}&ACCOUNTINGMODE=${this.config.tokenizer.SIA.ACCOUNTINGMODE_PAY}&AUTHORMODE=${this.config.tokenizer.SIA.AUTHORMODE}&OPTIONS=V&ORDDESCR=${siaRequest.ORDDESCR}`
            
            siaRequest.request = request

            var hash = crypto.HmacSHA256(request, this.config.tokenizer.SIA.APIKEY_BEGIN_PAY)
            var MAC = crypto.enc.Hex.stringify(hash)
            siaRequest.MAC = MAC

            this.SIARequest = siaRequest
        },
    },
    created() {
        this.getSIARequest(this.amount)
    }
}
</script>

<style lang="scss" scoped>
</style>