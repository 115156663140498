<template>
    <Navbar/>
    <main class="d-flex justify-content-center flex-wrap py-4 px-2">
        <div class="col-12 col-xl-8 d-flex flex-wrap p-4 border rounded-5 shadow-lg bg-white">
            <div class="col-12">
                <h2 class="text-first">
                    <router-link to="/dashboard" class="text-third router-link" v-if="this.$route.name !== 'DashboardIndex'">
                        <i class="fa-solid fa-chevron-left"></i>
                        {{ $t('Dashboard.back') }}
                    </router-link>
                </h2>
            </div>
            <div class="col-12 text-center my-4">
                <h1 class="text-dark">
                    {{ this.$route.name !== 'DashboardIndex' ? $t(`Dashboard.${this.$route.name}.RouteName`) : $t(`Dashboard.${this.$route.name}.Description.trenord.title`) }}
                </h1>
                <h3 class="text-dark" v-if="this.$route.name === 'DashboardIndex'">
                    {{ $t(`Dashboard.${this.$route.name}.Description.trenord.subtitle`) }}
                </h3>
            </div>
            <div class="col-12">
                <router-view></router-view>
            </div>
        </div>
    </main>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Navbar from '../../components/Dashboard/Navbar.vue'
export default {
    name: 'DashboardView',
    components: {
        Navbar
    },
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setLoader', 'setBankCards']),
        getBankCards() {
            this.setLoader(true)
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        bank_card.label = this.$formatLabel(bank_card.label)
                        bank_card.created = this.$formatDate(bank_card.created, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                        bank_card.updated = this.$formatDate(bank_card.updated, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                    }
                    this.setBankCards(bank_cards)
                    this.setLoader(false)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        }
    },
    created() {
        this.getBankCards()
    }
}
</script>